'use client';

import { useCallback } from 'react';
import moment from 'moment';

import { Col } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';
import createStyles from '@guestyci/foundation/createStyles';

import LocalizedDate from 'components/LocalizedDate';
import useGetRoomsLabel from 'hooks/useGetRoomsLabel';
import { UI_DATE_FORMAT } from 'constants/date';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  inputDataHeading: {
    fontSize: 14,
  },
  text: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  finalInputData: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 10,
    [create('xs')]: {
      gap: 10,
      flexWrap: 'wrap',
    },
    [create('xl')]: {
      flexWrap: 'nowrap',
    },
    '& > div': {
      position: 'relative',
      [create('xs')]: {
        width: 100,
      },
      '&:not(:last-child)': {
        '&:after': {
          content: '""',
          display: 'block',
          width: 1,
          height: '80%',
          top: 5,
          background: '#D4D8E1',
          right: -15,
          position: 'absolute',
          [create('xs')]: {
            display: 'none',
          },
        },
      },
    },
  },
}));

const DatesAndGuests = ({ startDate, endDate, guestsCount, rooms }) => {
  const { isRoomLabel } = useGetRoomsLabel();
  const { inputDataHeading, finalInputData, text } = useStyles();
  const nightsCount = endDate && startDate && endDate.diff(startDate, 'days');
  const guestsPluralLabel = t(
    `
  {
    num,
    plural,
    =0 {Guests}
    =1 {Guest}
    few {Guests}
    many {Guests}
    other {Guests}}
  `,
    { num: guestsCount }
  );

  const roomsPluralLabel = t(
    `
  {
    num,
    plural,
    =0 {Rooms}
    =1 {Room}
    few {Rooms}
    many {Rooms}
    other {Rooms}}
  `,
    { num: rooms }
  );

  const unitsPluralLabel = t(
    `
  {
    num,
    plural,
    =0 {Units}
    =1 {Unit}
    few {Units}
    many {Units}
    other {Units}}
  `,
    { num: rooms }
  );

  const nightsPluralLabel = t(
    `
  {
    num,
    plural,
    =0 {# Nights}
    =1 {1 Night}
    few {# Nights}
    many {# Nights}
    other {# Nights}}
  `,
    { num: nightsCount }
  );
  const renderFinalInputData = useCallback(
    ({ title, input }) => {
      return (
        <Col key={title}>
          <TextField color="secondary" className={inputDataHeading}>
            {title}
          </TextField>
          <TextField className={text}>{input}</TextField>
        </Col>
      );
    },
    [inputDataHeading, text]
  );

  const data = [
    {
      title: t('Check In'),
      input: <LocalizedDate formattedDate={moment(startDate).format(UI_DATE_FORMAT)} date={startDate} />,
    },
    {
      title: t('Check Out'),
      input: <LocalizedDate formattedDate={moment(endDate).format(UI_DATE_FORMAT)} date={endDate} />,
    },
    {
      title: t('Nights'),
      input: nightsPluralLabel,
    },
    {
      title: guestsPluralLabel,
      input: guestsCount,
    },
  ];

  if (rooms) {
    data.push({
      title: isRoomLabel ? roomsPluralLabel : unitsPluralLabel,
      input: rooms,
    });
  }

  return <div className={finalInputData}>{data.map(({ title, input }) => renderFinalInputData({ title, input }))}</div>;
};

export default DatesAndGuests;
