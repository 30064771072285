import { FormattedDate } from '@guestyci/localize';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import { DISCO_BE_DATE_FORMAT } from 'constants/featureToggleNames';
import useLocale from 'hooks/useLocale';

export const LocalizedDate = ({ date, locale, formattedDate }) => {
  const defaultLocale = useLocale();
  const [, isDateFormat] = useFeatureToggle(DISCO_BE_DATE_FORMAT);

  if (!isDateFormat) {
    return formattedDate;
  }

  return <FormattedDate date={date} locale={locale || defaultLocale} />
};

export default LocalizedDate;
