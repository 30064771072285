import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import useLocale from 'hooks/useLocale';
import { DISCO_BE_DATE_FORMAT } from 'constants/featureToggleNames';

const useLocalizedTime = ({ time = '', locale, formattedTime }) => {
  const defaultLocale = useLocale();
  const [, isDateFormat] = useFeatureToggle(DISCO_BE_DATE_FORMAT);

  if (!isDateFormat) {
    return formattedTime;
  }

  if (!time) {
    return null;
  }

  const date = new Date();
  const [timePart, ampm] = time.split(/(AM|PM)/i);
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = timePart.split(':').map(Number);

  if (ampm && ampm.toUpperCase() === 'PM' && hours !== 12) {
    hours += 12;
  } else if (ampm && ampm.toUpperCase() === 'AM' && hours === 12) {
    hours = 0;
  }

  date.setHours(hours || 0);
  date.setMinutes(minutes || 0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  const options = {
    hour: '2-digit',
    minute: '2-digit',
  };

  return new Intl.DateTimeFormat(locale || defaultLocale, options).format(date);
};

export default useLocalizedTime;
