import useLocalizedTime from 'hooks/useLocalizedTime';
import useLocale from 'hooks/useLocale';

const LocalizedTime = ({ time, locale, formattedTime }) => {
  const defaultLocale = useLocale();

  return useLocalizedTime({ time, locale: locale || defaultLocale, formattedTime });
};

export default LocalizedTime;
